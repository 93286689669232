main {
  padding: 0;
}
.main-wrap {
  padding: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.landing-page {
  &__logo {
    position: relative;
    z-index: 20;
    display: flex;
    justify-content: center;
    min-height: 15vh;
    padding: 1rem;
    figure {
      margin: auto;
      background-color: $white;
      border-radius: 16px;
      text-align: center;
    }
    img {
      max-width: 240px;
      max-height: 120px;
      object-fit: contain;
    }
  }
}
.intro-section {
  min-height: 60vh;
  align-items: stretch;
  gap: 0;
  .wf-column {
    * {
      position: relative;
      z-index: 20;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      inset: 0;
      opacity: 0.8;
    }
  }
  &__1 {
    &:after {
      background-color: $primary-colour-light;
    }
  }
  &__2 {
    &:after {
      background-color: $secondary-colour-light;
    }
  }
  &__3 {
    &:after {
      background-color: $tertiary-colour-light;
    }
  }
  &__4 {
    &:after {
      background-color: $tertiary-colour-dark;
      @include bp_max(large) {
        background-color: $secondary-colour-dark;
      }
    }
  }
  &__5 {
    &:after {
      background-color: $primary-colour-dark;
      @include bp_max(large) {
        background-color: $tertiary-colour-dark;
      }
    }
  }
  &__6 {
    &:after {
      background-color: $secondary-colour-dark;
      @include bp_max(large) {
        background-color: $primary-colour-dark;
      }
    }
  }
  &__logo {
    position: relative;
    z-index: 20;
    padding: 4rem 1rem 2rem;
    figure {
      max-width: 480px;
      margin: auto;
      padding: 2rem;
      background-color: $white;
      border-radius: 16px;
      text-align: center;
    }
  }
  .wf-columns {
    min-height: 30vh;
  }
  .wf-column {
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    figure {
      max-width: 480px;
      width: 100%;
      height: auto;
      margin-bottom: 2rem;
      padding: 2rem;
      background-color: $white;
      border-radius: 16px;
      text-align: center;
      img {
        display: none;
        object-fit: cover;
        width: 100%;
        height: auto;
        margin-bottom: 0.5rem;
      }
      p {
        margin: 0.25rem 0 0 0;
        font-weight: 500;
        font-size: 1rem;
        span {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }
  }
}

.event-details {
  strong {
    font-size: 1.2rem;
  }
}

#second-section {
  section {
    min-height: 20vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .row {
    width: 100%;
  }
}

footer {
  min-height: 5vh;
}
